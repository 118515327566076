import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo-smsf-loans.png"
import VisaDcard from "../../assets/pdf/footer/Visa-Debit-Card-Product-Sheet-Oxygen.pdf"
import Visacond from "../../assets/pdf/footer/VISA-Conditions-of-Use.pdf"



const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p className="copyright">
                &copy; {currentYear} 
                {/* Copyright{" "} */}
                <a target="_blank" href="/" rel="noreferrer">
                &nbsp; Oxygen Lending Services Pty Ltd
                </a>
              </p>
              <div className="company-info">ACN 618 760 222 </div>
              <div className="company-info">
                Australian Credit Licence Number 498349
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Information</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/calculators">Calculators</Link>
                </li>
                <li>
                  <Link to="/brokers">Find a Broker</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Help &amp; Support</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/complaints">Complaints</Link>
                </li>
                <li>
                  <Link to="/terms">Terms &amp; Conditions</Link>
                </li>
                {/* <li>
                  <Link to="/competition">Competition</Link>
                </li> */}
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/tmd">Target Market Determinations</Link>
                </li>
                <li>
                  {/* <Link to="/">Visa Conditions of Use</Link> */}
                  <a href={Visacond} target="_blank">Visa Conditions of Use</a>
                </li>
                <li>
                  <a href={VisaDcard} target="_blank">Visa Debit Card</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Connect With Us</h3>
              <ul className="social-link">
                {/*<li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>*/}
                <li>
                  <a
                    href="https://www.facebook.com/OxygenHomeLoans"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Oxygen Facebook page"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/oxygen-home-loans/"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Oxygen Linkedin Page"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

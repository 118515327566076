import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import { Container, Row, Col } from "react-bootstrap"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"

const privacypolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy | Oxygen - Home Loans Made Simple" />
      <Navbar />
      <div className="section-wrapper page-tmd page-pp">
        <section className=" pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>1. Privacy Policy </h3>

                <p>
                  <strong>Oxygen Home Loans</strong> and its related bodies
                  corporate including Oxygen Lending Services Pty Ltd, Oxygen
                  Broking Services Pty Ltd and Oxygen Capital Group Pty Ltd in
                  conjunction with Columbus Capital Pty Limited ACN 119 531 252
                  and its related bodies corporate in Australia and overseas
                  (the Columbus Group) are committed to protecting your privacy
                  and want you to understand how we collect, use, and share your
                  personal information.
                </p>
                <p>
                  The <em>Privacy Act 1988 </em>(Cth) (
                  <strong>Privacy Act</strong>), the Australian Privacy
                  Principles, Privacy Regulation 2013 (
                  <strong>Regulations</strong>) and registered privacy codes
                  govern the way in which we must manage your personal
                  information (<strong>Privacy Laws</strong>).
                </p>
                <p>
                  This Privacy Policy covers our data collection practices and
                  describes your rights to access, correct, or restrict our use
                  of your personal information as defined in the Privacy Act
                  (&ldquo;
                  <strong>data</strong>&ldquo;), as well as ensuring the
                  quality, integrity and security of your personal information
                  under applicable Privacy Laws.
                </p>
                <p>
                  This Privacy Policy applies when you visit or use our website,
                  mobile applications, APIs or when we are providing relevant
                  services to you (the &ldquo;<strong>Services</strong>&ldquo;).
                  We are committed to safeguarding the privacy of our website
                  visitors and service users.
                </p>
                <p>
                  <strong>
                    By using our Services, you agree to the terms of this
                    Privacy Policy
                  </strong>
                  . You shouldn&rsquo;t use our Services if you don&rsquo;t
                  agree with this Privacy Policy or any other agreement that
                  governs your use of the Services.
                </p>
                <p>
                  In this policy, &quot;<em>we</em>&quot;, &quot;<em>us</em>
                  &quot; and &quot;<em>our</em>&quot; means all of the Oxygen
                  Home Loans and Columbus Group's businesses including:
                </p>

                <ol className="alpha">
                  <li>
                    Oxygen Lending Services Pty Ltd (ACN 618 760 222) trading as
                    Oxygen Home Loans
                  </li>
                  <li>Oxygen Broking Services Pty Ltd (ACN 103 177 377)</li>
                  <li>
                    Columbus Capital Pty Limited ACN 119 531 252 and its related
                    bodies corporate (as defined in section 50 of the
                    <em>Corporations Act 2001 </em>(Cth);
                  </li>
                  <li>
                    Columbus Capital Pty Limited ACN 119 531 252 trading as
                    Origin Mortgage Management Services Australian Financial
                    Services Licence 337303 and Australian Credit Licence
                    337303; and
                  </li>
                  <li>the following websites:</li>
                  <ul className="roman">
                    <li>
                      <a href="http://www.colcap.com.au/" target="_blank">
                        www.colcap.com.au
                      </a>
                      ;
                    </li>
                    <li>
                      <a
                        href="http://www.homestarfinance.com.au/"
                        target="_blank"
                      >
                        www.oxygen.com.au
                      </a>
                      ;
                    </li>
                    <li>
                      <a href="http://www.oxygenloans.com.au" target="_blank">
                        www.oxygenloans.com.au
                      </a>
                      ; and
                    </li>
                    <li>
                      <a href="http://www.originmms.com.au/" target="_blank">
                        www.originmms.com.au,
                      </a>
                    </li>
                  </ul>
                </ol>

                <p>
                  all of the above to be referred to as the Columbus Capital
                  Group and <strong>Oxygen Home Loans</strong>.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>2. What is personal information? </h3>
                <p>
                  Personal information includes any information or opinion,
                  about an identified individual or an individual who can be
                  reasonably identified from that information. The information
                  or opinion will still be personal information whether it is
                  true or not and regardless of whether we have kept a record of
                  it.
                </p>
                <p>Some examples of personal information may include your: </p>
                <ol className="alpha">
                  <li>name;</li>
                  <li>date of birth;</li>
                  <li>citizenship;</li>
                  <li>
                    mailing or residential address details, the length of time
                    at your current address;
                  </li>
                  <li>
                    contact details such as telephone numbers, email address,
                    social media platform username (if you contact us through
                    social media platforms);
                  </li>
                  <li>occupation and place of work;</li>
                  <li>
                    government issued identifiers such as Tax File Number,
                    Medicare number or Driver&rsquo;s License number;
                  </li>
                  <li>bank account and credit card details;</li>
                  <li>
                    your employment details and proof of earnings and expenses;
                  </li>
                  <li>
                    if you are applying for credit we may also collect the ages
                    and number of your dependants and cohabitants;
                  </li>
                  <li>
                    credit history, credit capacity, ability to be provided with
                    credit or credit worthiness (
                    <strong>Credit Information</strong>);
                  </li>
                  <li>signature, photograph, video or audio recording; and</li>
                  <li>
                    in limited circumstances sensitive information such as
                    information relating to your health, biometric data,
                    criminal history, racial or ethnic origin.
                  </li>
                </ol>
                <p>
                  Credit Information is information which we use to assess your
                  eligibility to be provided with credit and may include any
                  credit that you have outstanding, your repayment history and
                  any defaults. Usually, Credit Information is exchanged between
                  credit and credit providers and credit reporting bodies. We
                  may use credit eligibility information being credit reporting
                  information supplied to us by a credit reporting body, and any
                  information that we derive from it to make decisions regarding
                  your eligibility for credit.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>3. How is Sensitive information handled? </h3>
                <p>
                  We generally do not collect &lsquo;
                  <em>sensitive information</em>&rsquo; as defined under the
                  Privacy Laws and we further restrict collection of such
                  sensitive information unless it is necessary for us to provide
                  our Services to you and where we have either obtained your
                  express consent or a permitted general situation exists. For
                  example, we may collect health information about you to assess
                  certain claims, including hardship, or we may collect voice
                  biometric information to verify your identity or authorise
                  transactions.
                </p>
                <p>
                  Sensitive information is personal information that includes
                  information relating to your racial or ethnic origin, criminal
                  history, sexual orientation, membership of any trade or
                  professional associations.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>4. Wish to stay Anonymous! </h3>
                <p>
                  You can withhold your personal information when speaking with
                  us if you are making a general enquiry. However, if you wish
                  for us to provide you with our Services, we will need to
                  identify you.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>5. Why we collect your personal information </h3>
                <p>
                  Any personal information that we collect is first obtained
                  through either notice or consent and the other legal basis for
                  the processing of your personal information is either for:
                </p>

                <ol className="alpha">
                  <li>
                    the performance of a contract between you and us and/or
                    taking steps, at your request, to enter into such a
                    contract;
                  </li>
                  <li>
                    you are posting any comments or direct messages on social
                    media sites;
                  </li>
                  <li>our legitimate interests, namely:</li>
                  <ul className="roman">
                    <li>
                      to comply with our legal and regulatory obligations;
                    </li>
                    <li>
                      the proper administration of our website and business;
                    </li>
                    <li>monitoring and improving our website and Services;</li>
                    <li>
                      the proper management of our customer relationships;
                    </li>
                    <li>communications with users;</li>
                    <li>
                      the protection and assertion of our legal rights, your
                      legal rights and the legal rights of others (as
                      applicable);
                    </li>
                    <li>
                      the proper protection of our business against risks; and
                    </li>
                    <li>for any other purpose permitted by law.</li>
                  </ul>
                </ol>

                <p>
                  We collect personal information for the purposes of either
                  working with you or your customers in assessing an application
                  and eligibility for credit, managing that credit, identifying
                  you and to comply with our legal obligations. We may also
                  collect your personal information for the purposes of direct
                  marketing and managing our relationship with you. From time to
                  time we may offer you other Services.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>6. How information is collected </h3>
                <p>
                  Most information will be collected from you personally, this
                  can be taken by us:
                </p>

                <ol className="alpha">
                  <li>if you call or email us;</li>
                  <li>when we provide our Services to you;</li>
                  <li>
                    when we manage our customer relationships and service
                    provider relationships;
                  </li>
                  <li>
                    from credit reporting bodies and from mortgage brokers,
                    mortgage managers, your representatives and other people
                    such as accountants and lawyers;
                  </li>
                  <li>if you provide us with feedback or make a complaint;</li>
                  <li>if we provide you with our Services;</li>
                  <li>if you apply for an account with us;</li>
                  <li>
                    when CCTV footage is recorded at our offices or premises;
                  </li>
                  <li>your information that is in the public domain;</li>
                  <li>
                    if you subscribe to our newsletters and marketing lists; and
                  </li>
                  <li>
                    other information that may be collected include details
                    provided on a resume sent to us relating to an employment
                    opportunity.
                  </li>
                </ol>

                <p>
                  We may obtain your
                  <strong>credit related personal information</strong>:
                </p>
                <ol className="alpha">
                  <li>
                    When making an application or negotiating with a lender on
                    your behalf.
                  </li>
                  <li>
                    From a Credit Reporting Body (&ldquo;<strong>CRB</strong>
                    &ldquo;) when we have obtained your credit report with your
                    consent.
                  </li>
                  <li>
                    We may also receive your personal information from another
                    party by any other means. If we do, we will apply the
                    Privacy Laws in deciding whether it is lawful to keep the
                    information received.
                  </li>
                  <li>
                    We may also receive your personal information from third
                    parties that we deal with on your behalf including brokers
                    and mortgage managers and from our other service providers.
                  </li>
                  <li>
                    Any information we receive that we are not lawfully required
                    to hold will be deleted or destroyed.
                  </li>
                </ol>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>7. How we use your personal information </h3>
                <p>
                  The main reason we collect, use, hold and disclose personal
                  information is to provide you with products and Services
                  (including where applicable, third party products and
                  services) and to help us run our business. This includes:
                </p>

                <ol className="alpha">
                  <li>confirming your identity;</li>
                  <li>
                    checking whether you are eligible for our products or
                    services;
                  </li>
                  <li>
                    assisting you where online applications are not completed;
                  </li>
                  <li>
                    providing our products or Services to you, including
                    administration of our Services and notifications about
                    changes to our Services;
                  </li>
                  <li>
                    helping manage the product or service that we provide to
                    you;
                  </li>
                  <li>
                    helping us develop insights and conduct data analysis to
                    improve the delivery of products, services, enhance our
                    customer relationships and to effectively manage risks;
                  </li>
                  <li>
                    minimise risks and identify or investigate fraud and other
                    illegal activities;
                  </li>
                  <li>
                    comply with laws and assist government or law enforcement
                    agencies;
                  </li>
                  <li>
                    record-keeping purposes, technical maintenance, obtaining or
                    maintaining insurance coverage, managing risks or obtaining
                    professional advice, managing our business – that is, to
                    carry on our business activities and provide our Services to
                    you;
                  </li>
                  <li>
                    to prevent fraud, crime or other activity that may cause
                    harm in relation to our Services and help us run our
                    business and maintain integrity;
                  </li>
                  <li>bringing you new products and services;</li>
                  <li>
                    understanding your interests and preferences so we can
                    tailor digital content;
                  </li>
                  <li>
                    as permitted by law and to comply with legislative or
                    regulatory requirements in any jurisdiction, for the
                    establishment, exercise or defence of legal claims, whether
                    in court proceedings or in an administrative or out-of-court
                    procedure, to prevent; and
                  </li>
                  <li>
                    In addition to the specific purposes for which we may
                    process your personal information set out above, we may also
                    process any of your personal information where such
                    processing is necessary for compliance with a legal
                    obligation to which we are subject, or in order to protect
                    your vital interests or the vital interests of another
                    natural person.
                  </li>
                </ol>

                <p>
                  We may also use your personal information to tell you about
                  our Services we think may interest you or for a purpose
                  related to the primary purpose of collection or where you
                  would reasonably expect that we would use the information in
                  such a way, subject to legal restrictions on using your
                  personal information for marketing purposes.
                </p>
                <p>
                  We may also de-identify your personal information which we
                  have collected for the purposes described in this Privacy
                  Policy.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>8. Disclosing your personal information </h3>
                <p>We may disclose your personal information: </p>

                <ol className="alpha">
                  <li>
                    to any member of the Columbus Capital Group of companies
                    insofar as reasonably necessary for the purposes of this
                    Privacy Policy and providing our Services, and on the legal
                    bases allowed under the Privacy Laws and as set out in this
                    Privacy Policy;
                  </li>
                  <li>
                    to prospective funders or other intermediaries in relation
                    to your credit requirements;
                  </li>
                  <li>
                    to other organisations that are involved in managing or
                    administering your credit such as third party suppliers,
                    brokers, mortgage managers, aggregators, lenders mortgage
                    insurers, trade insurers, valuers, third party service
                    providers, service providers for the purposes of verifying
                    your identity, surveyors, accountants, credit reporting
                    bodies, recoveries firms, debt collectors, lawyers, call
                    centres, printing and postal services;
                  </li>
                  <li>to regulatory and supervisory bodies;</li>
                  <li>
                    to associated businesses that may want to market products to
                    you;
                  </li>
                  <li>
                    to companies that provide information and infrastructure
                    systems to us;
                  </li>
                  <li>
                    to anybody who represents you, such as mortgage brokers,
                    mortgage managers, your representatives, lawyers, and
                    accountants;
                  </li>
                  <li>
                    related entities and third party service providers who
                    assist us in our operations and certain tasks including the
                    verifying of your identity and information technology
                    services;
                  </li>
                  <li>
                    to our suppliers or subcontractors insofar as reasonably
                    necessary to provide the relevant Services to you;
                  </li>
                  <li>
                    to anyone, where you have provided us with your consent;
                  </li>
                  <li>
                    where we are required to do so by law, such as under the
                    Anti-Money Laundering and Counter- Terrorism Financing Act
                    2006 (Cth); or{" "}
                  </li>

                  <li>
                    to investors, agents or advisers, or any entity that has an
                    interest in our business;
                  </li>
                  <li>
                    organisations that provide products or services used or
                    marketed by us; or
                  </li>
                  <li>to your employer or referees.</li>
                </ol>

                <p>
                  Prior to disclosing any of your personal information to
                  another person or organisation, we will take all reasonable
                  steps to satisfy ourselves that:
                </p>

                <ul className="roman">
                  <li>
                    the person or organisation has a commitment to protecting
                    your personal information at least equal to our commitment;
                  </li>
                  <li>
                    is legally able to seek access to your personal information
                    in accordance with the Privacy Laws or any other laws; or
                  </li>
                  <li>you have consented to us making the disclosure. </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>9 Overseas Recipients </h3>
                <p>
                  Prior to disclosing your personal information to an overseas
                  recipient, unless a permitted general situation applies, we
                  will take all reasonable steps to ensure that:
                </p>

                <ol className="alpha">
                  <li>
                    the overseas recipient does not breach the Privacy Laws; or
                  </li>
                  <li>
                    the overseas recipient is subject to a law, or binding
                    scheme, or contractual terms that has the effect of
                    protecting the information in a way that, overall, is at
                    least substantially similar to the way your personal
                    information is protected under the Privacy Laws; or
                  </li>
                  <li>you have consented to us making the disclosure.</li>
                </ol>

                <p>
                  Acceptance of any of our Services via an application in
                  writing, orally or electronic means, will be deemed as giving
                  consent to the disclosures detailed herein.
                </p>
                <p>
                  Currently we are handling, storing, and processing your data
                  in the following locations Australia, Asia and the Pacific
                  (via our contact centres in Fiji and Philippines) and USA
                  through the use of Microsoft products, Salesforce, AWS, 8x8,
                  Twillio, cloud storage, technological products and services
                  via other service providers.
                </p>
                <p>
                  The locations where we handle, store and process your data may
                  change as our business needs changes and we appoint other
                  service providers from time to time.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>10. Direct Marketing </h3>
                <p>
                  We may use your personal information for direct marketing.
                  This means we may send information to you that relates to
                  promotions within our Columbus Capital Group companies.
                </p>
                <p>
                  You have the right to object to our processing of your
                  personal information for direct marketing purposes. If you
                  make such an objection, we will cease to process your personal
                  information for this purpose.
                </p>
                <p>
                  If you do not wish to receive marketing information, you may
                  at any time decline to receive such information by calling us
                  on 1300 462 209 or by writing to us at PO Box A992 Sydney
                  South NSW 1235. If the direct marketing is by email you may
                  also use the unsubscribe function.
                </p>
                <p>
                  We will not sell your personal information to other companies
                  or organisations.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>11. Credit information </h3>
                <p>
                  Credit Reporting Bodies (<strong>CRB</strong>) are authorised
                  by law to handle your credit related information. If you apply
                  for credit, we may disclose your personal information to, or
                  collect personal credit related information from a CRB and
                  other entities.
                </p>
                <p>
                  CRBs may include credit related information provided by the
                  Columbus Capital Group in reports <br />
                  provided to other credit providers to assist such other credit
                  providers to assess the individual&rsquo;s credit worthiness.
                </p>
                <p>
                  As permitted by law, we may collect, hold, use or disclose
                  credit related information held about you for the purposes of:
                </p>

                <ol className="alpha">
                  <li>
                    credit liability information being information about your
                    existing credit which includes the name of the credit
                    provider, whether the credit provider holds an Australian
                    Credit Licence, the type of credit, the day the credit is
                    entered into, the terms and conditions of the credit, the
                    maximum amount of credit available, and the day on which the
                    credit was terminated;
                  </li>
                  <li>
                    repayment history information which is information about
                    whether you meet your repayments on time;
                  </li>
                  <li>
                    information about the type of credit that you are applying
                    for;
                  </li>
                  <li>
                    assessing and forming decisions as to whether to provide you
                    with credit or to accept an individual as a guarantor;
                  </li>
                  <li>
                    participating in the exchange of credit related information
                    with other credit providers including obtaining from and
                    providing information to CRBs and other credit providers
                    and/or trade suppliers as permitted by Part IIIA of the
                    Privacy Act and the Credit Reporting Code;
                  </li>
                  <li>
                    to assist you with debt management and administration;
                  </li>
                  <li>to provide you with our Services;</li>
                  <li>default and payment information;</li>
                  <li>
                    to undertake debt recovery and enforcement activities,
                    including in relation to guarantors, and to deal with
                    serious credit infringements;
                  </li>
                  <li>court proceedings information;</li>
                  <li>
                    to deal with complaints and meet legal and regulatory
                    requirements; and
                  </li>
                  <li>to assist other credit providers to do the same.</li>
                </ol>

                <p>
                  When we obtain credit information from a credit reporting body
                  about you, we may also seek publicly available information and
                  information about any serious credit infringement that you may
                  have committed.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>12. Credit Information handling and Credit Reporting </h3>
                <p>
                  In assessing your credit application and providing the
                  Services to you, we may exchange your personal information, as
                  well as your consumer and commercial credit information with
                  the following entities, including but not limited to:
                </p>

                <ol className="alpha">
                  <li>
                    Obtaining credit information about you from Equifax
                    Australia Information Services and Solutions Pty Limited
                    (ABN 26 000 602 862) (<strong>Equifax</strong>) and we may
                    provide to Equifax your personal information with respect
                    of:
                  </li>
                  <ul className="roman">
                    <li>
                      request for access seeker services in accordance with
                      section 6L of the Privacy Act; and
                    </li>
                    <li>
                      request for your credit information and credit report.
                    </li>
                  </ul>

                  <li>
                    Equifax and its related companies may use and disclose your
                    personal information to manage the provision of credit
                    reporting information and the access seeker services, and to
                    undertake data management for quality related purposes. The
                    Equifax privacy policy is available on the Equifax website
                    at {""}
                    <a href="https://www.equifax.com.au/privacy">
                      https://www.equifax.com.au/privacy,
                    </a>
                    and contains information about how Equifax handles personal
                    information (other than credit reporting information),
                    including how an individual may access his or her personal
                    information held by Equifax and its related companies and
                    seek the correction of that information, and how an
                    individual may complain about a breach of the Australian
                    Privacy Principles and how Equifax and its related companies
                    will deal with such a complaint.
                  </li>
                  <li>
                    The Equifax Credit Reporting Policy contains information
                    about how Equifax collects and handles credit reporting
                    information and is available on the Equifax website at {""}
                    <a href="https://www.equifax.com.au/credit-reporting-policy">
                      https://www.equifax.com.au/credit-reporting-policy
                    </a>
                    .
                  </li>
                  <li>
                    Obtaining your financial information via our third party
                    Service Provider illion Australia Pty Ltd ABN 95006399677
                    (Ilion) who is the owner of illion BankStatements
                    <a href="http://www.bankstatements.com.au/">
                      (www.bankstatements.com.au
                    </a>
                    ) whereby with your consent they can access your banking
                    transaction data and categorise it in an income and expense
                    report which assists us with assessing your ability to
                    service the loan applied for.
                  </li>
                  <li>
                    The Ilion privacy policy is available on the Ilion website
                    at {""}
                    <a href="https://www.illion.com.au/privacy-policy-risk-marketing-solutions/">
                      https://www.illion.com.au/privacy-
                    </a>
                    <a href="https://www.illion.com.au/privacy-policy-risk-marketing-solutions/">
                      policy-risk-marketing-solutions/,
                    </a>
                    and contains information about how Ilion handles personal
                    information, including how an individual may access his or
                    her personal information held by Ilion and its related
                    companies and seek the correction of that information, and
                    how an individual may complain about a breach of the
                    Australian Privacy Principles and how Ilion and its related
                    companies will deal with such a complaint.
                  </li>
                  <li>
                    Other credit providers for the purposes of assessing your
                    creditworthiness, credit standing, and credit history or
                    credit capacity.
                  </li>
                  <li>
                    Finance brokers, mortgage managers, our accountants,
                    lawyers, mortgage insurers and such other persons who assist
                    us to provide our Services to you such as:
                  </li>
                  <ul className="roman">
                    <li>
                      Lenders Mortgage Insurers (<strong>LMIs</strong>) who
                      hold, use and disclose your personal information and
                      credit information for the purposes of assessing whether
                      to provide insurance to us, including to assess the risk
                      of you defaulting or the risk of a guarantor being unable
                      to meet their liability, managing the insurance, dealing
                      with claims, enforcing any mortgage and recovering
                      proceeds, conducting risk and credit assessments, fraud
                      prevention, and verifying personal information provided by
                      us or any purpose under the insurance contract. The LMIs
                      that we may disclose your personal information and credit
                      information to are:
                    </li>
                    <ol className="upper">
                      <li>
                        Genworth Financial Mortgage Insurance Pty Ltd ACN 106
                        974 305 who can be contacted and a copy of their privacy
                        policy can be obtained by calling on 1300 655 422 or
                        their website at {""}
                        <a href="https://www.genworth.com.au/privacy-policy/">
                          https://www.genworth.com.au/privacy-policy/
                        </a>
                        ;
                      </li>
                      <li>
                        QBE Lenders Mortgage Insurance Limited ACN 000 511 071
                        who can be contacted and a copy of their privacy policy
                        can be obtained by calling on 1300 367 764 or their
                        website at {""}
                        <a href="https://www.qbe.com/lmi/about/governance/privacy-policy">
                          https://www.qbe.com/lmi/about/governance/privacy-policy
                        </a>
                        ; and
                      </li>
                      <li>
                        Arch Lenders Mortgage Indemnity Limited ACN 074 042 934
                        who can be contacted and a copy of their privacy policy
                        can be obtained on their website at {""}
                        <a href="https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fmortgage.archgroup.com%2Fwp-content%2Fuploads%2Fsites%2F4%2FLMI-Privacy-Policy.pdf&data=05%7C01%7CMarjana.Cvetanoska%40colcap.com.au%7Cac26b4288d024243059f08da42b0620f%7C285ef20059bb41f88e50baada7d96ff8%7C0%7C0%7C637895622568624620%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=f5eBd66e7yxYWc%2BNmH8nXDg3qFlCXfrINfYGECzUlLc%3D&reserved=0">
                          https://mortgage.archgroup.com/wp-content/uploads/sites/4/LMI-Privacy-
                        </a>
                        <a href="https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fmortgage.archgroup.com%2Fwp-content%2Fuploads%2Fsites%2F4%2FLMI-Privacy-Policy.pdf&data=05%7C01%7CMarjana.Cvetanoska%40colcap.com.au%7Cac26b4288d024243059f08da42b0620f%7C285ef20059bb41f88e50baada7d96ff8%7C0%7C0%7C637895622568624620%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=f5eBd66e7yxYWc%2BNmH8nXDg3qFlCXfrINfYGECzUlLc%3D&reserved=0">
                          Policy.pdf.
                        </a>
                      </li>
                    </ol>
                    <li>Our Funders we may use include:</li>
                    <ol className="upper">
                      <li>
                        Perpetual Corporate Trust Limited ACN 000 341 533 a copy
                        of their privacy policy can be found on their website at{" "}
                        {""}
                        <a href="https://www.perpetual.com.au/privacy-policy">
                          https://www.perpetual.com.au/privacy-policy
                        </a>
                      </li>
                      <li>
                        Permanent Custodians Ltd (and associated entities) ACN
                        001 426 384 a copy of their privacy policy can be found
                        on their website at {""}
                        <a href="https://www.bnymellon.com/au/en/index.jsp%23ir/privacy">
                          https://www.bnymellon.com/au/en/index.jsp#ir/privacy
                        </a>
                        .
                      </li>
                    </ol>
                    <li>
                      Indue Ltd ABN 97 087 822 464 as the Visa Debit card
                      issuer, if a loan product that we provide to you includes
                      access to a Visa Debit card, a copy of their privacy
                      policy can be found on their website at {""}
                      <a href="http://www.indue.com.au/legal-privacy-policy-cookies">
                        www.indue.com.au/legal-privacy-policy-cookies.
                      </a>
                    </li>
                  </ul>
                </ol>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>13. Your Rights in Relation to CRBs </h3>
                <p>
                  You may be asked to participate in a &ldquo;
                  <em>pre-screening</em>&ldquo;. This is where your credit
                  related information is provided to a CRB to use, to provide
                  marketing relating to your credit related circumstances. You
                  have the right to contact the CRB and ask that you be excluded
                  from this process.
                </p>
                <p>
                  If you have been or have a reasonable belief that you are
                  likely to be a victim of fraud, you can contact the CRB and
                  request for a &ldquo;<em>ban-period</em>&ldquo;. For a period
                  of 21 days after the credit reporting body receives your
                  notification the credit reporting body must not use or
                  disclose that credit information. <br />
                  The CRB will not be permitted to use your personal or credit
                  related information during this time.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>14. Notifiable matters </h3>
                <p>
                  From February 2018, the Privacy Act includes a new
                  <em>Notifiable Data Breaches scheme </em>(<strong>NDB</strong>
                  ) which requires us to notify you and the Office of the
                  Australian Information Commissioner (<strong>OAIC</strong>) of
                  certain data breaches and recommend steps you can take to
                  limit the impacts of a breach (for example, a password
                  change).
                </p>
                <p>
                  The NDB scheme requires us to notify about a data breach that
                  is likely to result in serious harm to affected individuals.
                  There are exceptions where notification is not required. For
                  example, where we have already taken appropriate remedial
                  action that removes the risk of serious harm to any
                  individuals.
                </p>
                <p>
                  If we believe there has been a data breach that impacts your
                  personal information and creates a likely risk of serious
                  harm, we will notify you and the OAIC as soon as possible and
                  keep in close contact with you about the nature of the breach,
                  the steps we are taking and what you can do to reduce the
                  impacts to your privacy.
                </p>
                <p>
                  If you believe that any personal information, we hold about
                  you has been impacted by a data breach, you can contact us
                  using the contact details below.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>15. Updating your personal information </h3>
                <p>
                  It is important to us that the personal information we hold
                  about you is accurate and up to date. During the course of our
                  relationship with you, we may ask you to inform us if any of
                  your personal information has changed.
                </p>
                <p>
                  If you wish to make any changes to your personal information,
                  you may contact us. We will generally rely on you to ensure
                  the information we hold about you is accurate or complete.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>16. Your Privacy Rights </h3>
                <p>
                  In this section 16, we have summarised the rights that you
                  have under the Privacy Laws. Some of the rights are complex,
                  and not all of the details have been included in our
                  summaries. Accordingly, you should read the relevant laws and
                  guidance from the regulatory authorities for a full
                  explanation of these rights.
                </p>
                <p>
                  The summary of your principal rights under Privacy Laws are:
                </p>

                <ol className="alpha">
                  <li>
                    to request, at any time, for us to inform you of the
                    personal information we hold about you;
                  </li>
                  <li>
                    the right to access your personal information and we will
                    respond to you within 30 days of making a request;
                  </li>
                  <li>
                    the right to rectification of your personal information;
                  </li>
                  <li>
                    the right to erasure (where we have no legitimate right or
                    business requirements to retain your personal information);
                  </li>
                  <li>
                    the right to restrict or object to processing (where we have
                    no legitimate right or business requirements to process your
                    personal information);
                  </li>
                  <li>the right to complain to a supervisory authority; and</li>
                  <li>
                    the right to withdraw your consent (where we have no
                    legitimate right or business requirements to retain or
                    process your personal information).
                  </li>
                </ol>

                <p>
                  We may refuse to give you access to personal information we
                  hold about you if we reasonably believe that giving access
                  would pose a serious threat to the life, health or safety of
                  an individual, or to the public health or safety, where giving
                  access would be unlawful, where giving access would have an
                  unreasonable impact on the privacy of other individuals, if
                  there are legal proceedings, or if we consider the request to
                  be frivolous or vexatious.
                  <br />
                  If we refuse to give you access to or to correct your personal
                  information, we will give you a notice explaining our reasons
                  except where it would be unreasonable to do so.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>
                  17. How safe and secure is your personal information that we
                  hold?
                </h3>
                <p>
                  We will take reasonable steps to protect your personal
                  information by storing it in a secure environment. We may
                  store your personal information in paper and electronic form.
                  We will also take reasonable steps to protect any personal
                  information from misuse, loss and unauthorised access,
                  modification or disclosure.
                </p>
                <p>
                  If we are no longer required or wish to keep your personal
                  information for the purpose it was collected, we will securely
                  destroy it or remove all identity features from the
                  information unless we are legally required to keep it for a
                  period of 7 years after an account is closed.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>18. Complaints Handling </h3>
                <p>
                  <strong>&nbsp;</strong>
                </p>
                <h4>Contact Us </h4>
                <p>
                  You may exercise any of your rights in relation to your
                  personal information by contacting us. If you have a question
                  or complaint about how your personal information is being
                  handled by the Oxygen Home Loans, our affiliates or contracted
                  service providers, please contact us first on the following
                  email: {""}
                  <a href="mailto:service@originmms.com.au">
                    oxygen@oxygen.com.au.
                  </a>
                  <br />
                  We will try to have your complaint resolved within 5 business
                  days, but it may take longer depending on the complaint. If
                  this is the case, we will aim to resolve your complaint within
                  30 days.
                </p>
                <p>
                  You can request further information about the way we manage
                  the personal information that we hold, or make a complaint, by
                  contacting our:
                </p>

                <strong>Privacy Officer </strong>
                <br />
                <ul>
                  <li>telephoning: 1300 855 699</li>
                  <li>e-mailing: <a href="mailto:oxygen@oxygen.com.au">oxygen@oxygen.com.au</a> </li>
                  <li>
                    writing to: Oxygen Home Loans
                    <br />
                    <span>Level 12, 92 Pitt St</span>
                    <br />
                    <span>Sydney NSW 2000</span>
                  </li>
                </ul>

                <p>
                  If you are dissatisfied with the response of our Complaints
                  Officer you may make a complaint to our External Dispute
                  Resolution Scheme:
                </p>
                <h4>
                  Australian Financial Complaints Authority Limited (AFCA)
                </h4>
                <ul>
                  <li>GPO Box 3</li>
                  <li>Melbourne VIC 3001</li>
                  <li>
                    Telephone: 1800 931 678 
                  </li>
                  <li>Email:
                    <a href="mailto:info@afca.org.au"> info@afca.org.au</a>
                  </li>
                  <li>
                    Website:
                    <a href="http://www.afca.org.au/"> www.afca.org.au/</a>
                  </li>
                </ul>
                <h4>The Office of the Australian Information Commissioner </h4>
                <p>
                  Under the Privacy Laws you may also complain to the Office of
                  the Australian Information Commissioner (<strong>OAIC</strong>
                  ) about the way we handle your personal information. Please
                  note the OAIC requires that any complaint be first made to the
                  respondent organisation, which is us. The law also allows 30
                  days for the respondent organisation to deal with the
                  complaint before a person may make a complaint to the OAIC.
                  <br />
                  The Commissioner can be contacted at:
                </p>
                <h4>Office of the Australian Information Commissioner </h4>
                <ul>
                  <li>GPO Box 5218</li>
                  <li>Sydney NSW 2001</li>
                  <li>Phone: 1300 363 992</li>
                  <li>
                    Email: {""}
                    <a href="mailto:enquiries@oaic.gov.au">
                      enquiries@oaic.gov.au
                    </a>{" "}
                  </li>
                  <li>
                    Website:{" "}
                    <a href="http://www.oaic.gov.au/">www.oaic.gov.au</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>19. Amendments </h3>
                <p>
                  We may update this Privacy Policy from time to time by
                  publishing a new version on our website. <br />
                  You should check this page occasionally to ensure you are
                  happy with any changes to this Privacy Policy. <br />
                  This Privacy Policy is dated 1 June 2022.
                </p>
                <p>&nbsp;</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <h3>20. Consent for Electronic Acceptance and Notices </h3>
                <p>
                  You consent to us giving you notices and other documents in
                  connection with our dealings with you by email. You understand
                  that upon your giving of this consent:
                </p>
                <ol className="alpha">
                  <li>
                    we will no longer send paper copies of notices and other
                    documents to you;
                  </li>
                  <li>
                    you should regularly check your nominated email address
                    below for notices and other documents;
                  </li>
                  <li>
                    you may withdraw your consent to the giving of notices and
                    other documents by email at any time; and
                  </li>
                  <li>
                    you have facilities to enable you to print the notice or
                    other document that we send to you by email if you desire.
                  </li>
                </ol>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </Layout>
  )
}

export default privacypolicy
